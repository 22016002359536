import Base from './Base';

class Videos extends Base {
	/**
	 * Init
	 */
	ready() {
		this.initVideos();
	}

	// Detect video host
	getVideoHost = url => {
		// Regular expression for YouTube URLs
		const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/)[\w-]+/;
		// Regular expression for Vimeo URLs
		const vimeoRegex = /^(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/(video\/)?\d+/;
		// Regular expression for common video file extensions
		const videoFileRegex = /\.(mp4|avi|mov|flv|wmv|webm|m4v|mkv|mpg|mpeg|vob|asf|divx|ogv|3gp)$/i;


		if (youtubeRegex.test(url)) {
			return 'youtube';
		} else if (vimeoRegex.test(url)) {
			return 'vimeo';
		} else if (videoFileRegex.test(url)) {
			return 'file';
		} else {
			return false;
		}
	}
	/**
	 * Get YouTube video ID
	*/
	getYouTubeVideoID = url => {
		// Regular expression for extracting the YouTube video ID
		const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;

		const match = url.match(regex);
		return match ? match[1] : null;
	}

	// Get Vimeo video ID
	getVimeoVideoID = url => {
		// Regular expression for extracting the Vimeo video ID
		const regex = /(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/[^\/]+\/videos\/|album\/\d+\/video\/|video\/|)(\d+)|vimeo\.com\/(?:\w*\/)?(\d+))/i;

		const match = url.match(regex);
		return match ? match[1] || match[2] : null;
	}

	/**
	 * Replace videos
	 */
	initVideos() {
		// Bail, if no videos are replaceable.
		if (!wp_dark_mode_json.options.video_replaces) return false

		// Manipulate wp_dark_mode_json.options.video_replaces
		wp_dark_mode_json.options.video_replaces = wp_dark_mode_json.options.video_replaces.map((vid) => {

			['light', 'dark'].forEach((mode) => {
				// Get video host.
				const host = this.getVideoHost(vid[mode]);

				// If YouTube, get video ID and construct dark mode video src correctly.
				if ('youtube' === host) {
					const videoID = this.getYouTubeVideoID(vid[mode]);
					if (!videoID) return;

					vid[mode] = `https://www.youtube.com/embed/${videoID}`;
				}

				// If Vimeo, get video ID and construct dark mode video src correctly.
				if ('vimeo' === host) {
					const videoID = this.getVimeoVideoID(vid[mode]);
					if (!videoID) return;

					vid[mode] = `https://player.vimeo.com/video/${videoID}`;
				}
			});

			return vid;
		});

		// Set global CSS vid opacity inherit
		document.body.style.setProperty('--wp-dark-mode-vid-opacity', 1);

		// Get all embedded video files.
		this.replaceVideos();
		this.adjustEmbeds();
		this.setVideoFilters();

		// Replace external hosted videos.
		// this.


		WPDarkMode.onChange(() => {
			this.replaceVideos();
			this.setVideoFilters();
		});
	}

	/**
	 * Replaces Direct Videos from  URL
	 */
	replaceVideos() {
		const videos = document.querySelectorAll('video, iframe, video source');

		// Bail, if videos are not set.
		if (!videos || !videos.length) return;

		// Replace videos.
		videos.forEach((video) => {
			// Bail, if video src is not set.
			if (!video.src) return;

			let currentSrc = video.dataset.lightSrc || video.src;


			// Add wp-dark-mode-ignore class.
			video.classList.add('wp-dark-mode-ignore');

			if (WPDarkMode.isActive) {
				const darkSrc = this.getDarkSrc(currentSrc);
				if (darkSrc) {
					video.dataset.lightSrc = currentSrc;
					video.dataset.darkSrc = darkSrc;
					video.src = darkSrc;
					video.srcset = darkSrc;
				}
			} else {
				const lightSrc = video.dataset.lightSrc;
				if (lightSrc) {
					video.src = lightSrc;
					video.srcset = lightSrc;
				}
			}

		});
	}

	/**
	 * Get dark src
	 * 
	 * @param {string} src 
	 * @returns 
	 */
	getDarkSrc(src = null) {
		// Bail, if src is not set.
		if (!src) return;

		const replaceable_videos = wp_dark_mode_json.options.video_replaces || false;
		if (!replaceable_videos) return false

		const videoPair = replaceable_videos.find((vid) => {
			// remove query string from video src
			const srcWithoutQuery = src.split('?')[0];

			return vid.light === srcWithoutQuery
		});

		if (!videoPair) return false

		return videoPair.dark;
	}

	/**
	 * Adjust embeds
	 * 
	 */
	adjustEmbeds(){
		// detect all the embeds iframe videos from YouTube and Vimeo
		const embeds = document.querySelectorAll('iframe');

		// Bail, if embeds are not set.
		if (!embeds || !embeds.length) return;

		// Filter embeds.
		const embeddedVideos = Array.from(embeds).filter((embed) => {
			// Bail, if embed src is not set.
			if (!embed.src) return false;

			// Get video host.
			const host = this.getVideoHost(embed.src);

			// If YouTube or Vimeo, return embed.
			if ('youtube' === host || 'vimeo' === host || 'file' === host) {
				return true;
			}

			return false;
		});

		// Bail, if no embeds are found.
		if (!embeddedVideos || !embeddedVideos.length) return;

		embeddedVideos.forEach((embed) => {
			if ( !embed || !embed.style ) return;
			embed.style.filter = 'brightness(var(--wp-dark-mode-video-brightness, 100%)) grayscale(var(--wp-dark-mode-video-grayscale, 0%))';
		});
	}


	/**
	 * Exclude low brightness videos
	 */
	setVideoFilters() {
		// Log('Filtering');
		const lowBrightnessExcludes = wp_dark_mode_json.options.video_enabled_low_brightness ? wp_dark_mode_json.options.video_low_brightness_excludes : [];
		const lowGrayscaleExcludes = wp_dark_mode_json.options.video_enabled_low_grayscale ? wp_dark_mode_json.options.video_low_grayscale_excludes : []

		const videos = document.querySelectorAll('video, iframe, video source');

		if(videos && videos.length) {
			videos.forEach((video) => {
				const src = video.src;

				// Log('video src', src);
	
				if( lowBrightnessExcludes && lowBrightnessExcludes.includes(src) ) {
					video?.style.setProperty('--wp-dark-mode-video-brightness', '100%');
				}
	
				if( lowGrayscaleExcludes && lowGrayscaleExcludes.includes(src) ) {
					video?.style.setProperty('--wp-dark-mode-video-grayscale', '0');
				}
			});
		}
		
	}

}

export default new Videos();