import Base from './Base'
import WPDarkMode from './DarkMode'
import * as Switches from '../switches';
import Accessibility from './Accessibility'

class DarkModeSwitch extends Base {

	/**
	 * Get switch elements
	 */
	getSwitches(exclude = '') {

		if (exclude !== '') {
			return document.querySelectorAll(`.wp-dark-mode-switch:not(${exclude})`);
		}

		return document.querySelectorAll('.wp-dark-mode-switch');
	}

	/**
	 * init
	 */
	ready() {

		const source = typeof wp_dark_mode_admin_json !== 'undefined' ? wp_dark_mode_admin_json : wp_dark_mode_json;

		// Bail, if not enabled.
		if (source && source.is_excluded)  return;

		this.injectSwitches();

		this.listenSwitches();
		this.reactiveSwitches();

		this.throttle = false;

		// Inject switches when mutation occurs in BODY.
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (this.throttle) {
					return;
				}
				// Log('ClassList changed!');

				this.throttle = true;
				setTimeout(this.injectSwitches, 200);
				setTimeout(() => {
					this.throttle = false;
				}, 500);
			});
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
			attributes: false,
		});
	}

	/**
	 * Inject switches to DOM
	 */
	injectSwitches = () => {
		const switches = this.getSwitches('.ignore');

		if (!switches || !switches.length) {
			return;
		}

		const THIS = this;
		// Log('injectSwitches', switches);

		switches.forEach((switchEl) => {

			// Bail, if already injected.
			if (switchEl.querySelector('.wp-dark-mode-switch-styled')) {
				return;
			}

			const data_keys = ['style', 'size', 'reactive', 'textLight', 'textDark', 'iconLight', 'iconDark'];

			const config = {}
			data_keys.forEach((key) => {
				config[key] = switchEl.dataset[key] || null;
			})

			// Return if style is not defined.
			if (!config.style) {
				return;
			}

			// Log('config', config);
			const switchHtml = THIS.buildSwitch(config);
			switchEl.innerHTML = switchHtml;
		});

		// Event to update switches.
		this.emit('injected');
	}

	/**
	 * Listen switch clicks
	 */
	listenSwitches() {
		document.addEventListener('click', (event) => {

			// Check if the clicked element is a Dark Mode switch
			const isSwitch = event.target.closest('.wp-dark-mode-switch') || event.target.matches('.wp-dark-mode-switch');
			// Bail, if not a Dark Mode switch
			if (!isSwitch) {
				return;
			}

			// Bail, if element contains .dummy class.
			if (event.target.closest('.dummy')) {
				return;
			}

			this.emit('click', event);

			// Prevent default action
			event.preventDefault();

			// Log('event.target', event.target);
			// if target has _font in parent or self
			if (event.target.closest('._font') || event.target.matches('._font')) {
				// Log('font toggle');
				Accessibility.toggleFontSize( event.target.closest('.wp-dark-mode-switch ._font') );

				this.emit('font-toggled', event);
			} else {
				// Log('not font toggle');
				WPDarkMode.toggle();
				WPDarkMode.remember();

				this.emit('toggled', event);
			}

		});
	}

	/**
	 * Reactive switches
	 */
	reactiveSwitches() {

		// Initial update switches.
		this.updateSwitches();

		// Update switches on change dark mode.
		WPDarkMode.onChange(this.updateSwitches);
	}

	/**
	 * Update Switches
	 */
	updateSwitches = () => {
		const switches = this.getSwitches('.dummy');

		if (!switches || !switches.length) {
			return;
		}

		switches.forEach((switchEl) => {
			const _scheme = switchEl.querySelector('._scheme')
			if (_scheme) {
				// for Accessibility 
				_scheme.classList[WPDarkMode.isActive ? 'add' : 'remove']('active');
			} else if (switchEl.querySelector('.switch')) {
				// For admin
				const switchChild = switchEl.querySelector('.switch')
				switchChild.classList[WPDarkMode.isActive ? 'add' : 'remove']('active');
			} else {
				// for normal 
				let instantChild = switchEl.querySelector('& > div:not(.light):not(.dark)')
				if( ! instantChild ) instantChild = switchEl
				instantChild.classList[WPDarkMode.isActive ? 'add' : 'remove']('active');
			}
		});

		// Event to update switches.
		this.emit('updated');
	}

	/**
	 * Build switch
	 *
	 * @param {string} style
	 * @param {string} size 
	 * @returns {string}
	 */
	buildSwitch(config = {}) {
		const isActive = WPDarkMode.isActive;
		const isReactive = config.reactive !== false;

		let html = `<div class="wp-dark-mode-switch-styled wp-dark-mode-switch-${config.style || 1} ${isActive && isReactive ? 'active' : ''}" style="--wp-dark-mode-switch-scale: ${config.size || 1}">`;

		// Adjust switch style.
		const switchStyle = 'Switch_' + (config.style || 1);

		// Wrap iconLight and iconDark inside img if exists.
		config.iconLight = config.iconLight ? `<img src="${config.iconLight}" width="100%"></img>` : null
		config.iconDark = config.iconDark ? `<img src="${config.iconDark}" width="100%"></img>` : null

		html += Switches[switchStyle](config);

		html += `</div>`;

		return html;
	}

	// developer events 
	clicked = (callback) => {
		this.on('click', callback);
	}

}

export default new DarkModeSwitch();